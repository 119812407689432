import * as React from 'react'

import styled from '@emotion/styled'

const CopyRightLayout = styled.div``

export const CopyRight = (): JSX.Element => (
  <CopyRightLayout>
    &copy; {new Date().getFullYear()} Carina Martins
  </CopyRightLayout>
)
