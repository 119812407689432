import * as React from 'react'

import styled from '@emotion/styled'

import { SocialIcons } from '../elements/links/SocialIcons/SocialIcons'
import { CopyRight } from './CopyRight/CopyRight'

const FlexFooter = styled.footer`
  display: flex;
  flex-direction: row;
  border-top: 1px solid #dddfe0;
  padding-top: 1.5rem;
  margin-top: max(30px, 3%);
  padding-bottom: max(30px, 3%);
  max-width: 1250px;
  background-color: #fff;
  @media (min-width: 1441px) {
    max-width: 2500px;
  }
`

export const Footer = (): JSX.Element => (
  <FlexFooter>
    <SocialIcons />
    <CopyRight />
  </FlexFooter>
)
