import * as React from 'react'
import type { ReactNode } from 'react'

import styled from '@emotion/styled'
import { Link as GatsbyLink } from 'gatsby'

import { MenuBars } from '../../elements/iconography/MenuIcons'
import { MenuButton } from '../MobileMenu/MobileMenu'

const isActive =
  (className?: string) =>
  ({ isCurrent }: { isCurrent?: boolean }) => {
    const activeClassName = { className: `${className} active` }
    if (isCurrent) {
      return activeClassName
    }
    return { className }
  }

const CustomLink = ({
  className,
  children,
  ...props
}: {
  className?: string
  children?: ReactNode
  [x: string]: unknown
}) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <GatsbyLink getProps={isActive(className)} {...props}>
    {children}
  </GatsbyLink>
)
type LinkProps = {
  color?: string
  mobile?: boolean
}

export const StyledLink = styled(CustomLink)<LinkProps>`
  ${props => (props.mobile ? undefined : 'margin-right: 1.5rem')};
  text-decoration: none;
  position: relative;
  color: ${props => props.color || '#000'};
  font-weight: 400;
  margin-bottom: 20px;
  line-height: 23px;
  text-transform: uppercase;
  :last-of-type {
    margin-right: 0;
  }
  :not(.active):hover {
    right: 0;
    text-decoration: none;
    color: ${props => props.color || '#000'};
  }

  :not(.active):hover:after {
    border-color: ${props => props.color || '#000'};
    right: 0;
  }

  :not(.active):after {
    color: ${props => props.color || '#000'};
    border-radius: 1rem;
    border-top: 1px solid ${props => props.color || '#000'};
    content: '';
    position: absolute;
    right: 100%;
    bottom: -0.125rem;
    left: 0%;
    transition: right 0.4s cubic-bezier(0, 0.5, 0, 0.5),
      border-color 0.4s ease-in;
  }
  :not(.active):hover:after {
    animation: anchor-underline 2s cubic-bezier(0, 0.5, 0, 1);
    border-color: ${props => props.color || '#000'};
    color: ${props => props.color || '#000'};
  }
  @keyframes anchor-underline {
    0%,
    10% {
      left: 0;
      right: 100%;
    }
    40%,
    60% {
      left: 0;
      right: 50%;
    }
    90%,
    100% {
      left: 0%;
      right: 50%;
    }
  }

  &.active {
    color: ${props => props.color || '#000'};
  }

  &.active::after {
    border-radius: 1rem;
    border-top: 1px solid ${props => props.color || '#000'};
    content: '';
    position: absolute;
    right: 60%;
    bottom: -0.125rem;
    left: 0%;
  }
`

export const MobileMenuStyledLink = styled(StyledLink)`
  font-size: 1.5rem;
  margin-bottom: 25px;
  line-height: 25px;
`

const StyledNav = styled.nav`
  margin-bottom: 0.25rem;
  max-width: 50vw;
  @media (max-width: 1023px) {
    display: none;
  }
`

const MobileMenuContainer = styled.div`
  margin-bottom: 0.25rem;
  max-width: 50vw;
  text-align: end;
  @media (min-width: 1024px) {
    display: none;
  }
`

export const NavLinks = ({
  menuBool,
  menuToggle,
}: {
  menuBool: boolean
  menuToggle: React.Dispatch<React.SetStateAction<boolean>>
}): JSX.Element => (
  <>
    <StyledNav>
      <StyledLink to="/" className={undefined}>
        Work
      </StyledLink>
      <StyledLink to="/about" className={undefined}>
        About
      </StyledLink>
      <StyledLink to="/contact" className={undefined}>
        Contact
      </StyledLink>
    </StyledNav>
    <MobileMenuContainer>
      <MenuButton onClick={() => menuToggle(!menuBool)}>
        <MenuBars fillColor="#000" size={'24'} />
      </MenuButton>
    </MobileMenuContainer>
  </>
)

export const PTNavLinks = ({
  menuBool,
  menuToggle,
}: {
  menuBool: boolean
  menuToggle: React.Dispatch<React.SetStateAction<boolean>>
}): JSX.Element => (
  <>
    <StyledNav>
      <StyledLink to="/pt/" className={undefined}>
        Projectos
      </StyledLink>
      <StyledLink to="/pt/sobre" className={undefined}>
        Sobre
      </StyledLink>
      <StyledLink to="/pt/contacto" className={undefined}>
        Contacto
      </StyledLink>
    </StyledNav>
    <MobileMenuContainer>
      <MenuButton onClick={() => menuToggle(!menuBool)}>
        <MenuBars fillColor="#000" size={'24'} />
      </MenuButton>
    </MobileMenuContainer>
  </>
)
