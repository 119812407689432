/* eslint-disable import/no-default-export */
/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

type SeoProps = {
  description?: string
  keywords?: string
  image?: string
  lang?: string
  meta?:
    | { name: string; content: unknown; property?: undefined }[]
    | { property: string; content: unknown; name?: undefined }[]
  title: string
  location?: Location
  alternate: string
}

const Seo = ({
  description,
  keywords,
  lang,
  meta = [],
  title,
  location,
  alternate,
}: SeoProps): JSX.Element => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            social {
              twitter
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  // console.log(description, lang, meta, title, metaDescription, keywords)
  // console.log(site)
  // console.log(alternate)
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : undefined}
        meta={[
          {
            name: 'author',
            content: 'Carina Martins',
          },
          {
            name: 'description',
            content: metaDescription,
          },
          {
            name: 'keywords',
            content: keywords,
          },
          {
            property: 'og:title',
            content: title,
          },
          {
            property: 'og:description',
            content: metaDescription,
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:creator',
            content: site.siteMetadata?.social?.twitter || '',
          },
          {
            name: 'twitter:title',
            content: title,
          },
          {
            name: 'twitter:description',
            content: metaDescription,
          },
        ].concat(meta)}
      />
      <Helmet>
        <link
          rel="alternate"
          hrefLang={lang === 'en' ? 'pt' : 'en'}
          href={alternate}
        />
      </Helmet>
    </>
  )
}

export default Seo
