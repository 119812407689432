import * as React from 'react'
import type { ReactNode } from 'react'

import styled from '@emotion/styled'

const FlexMain = styled.main`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* margin-top: max(50px, 3%); */
  /* display: block; */
`

const FlexBigMain = styled.main`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
`

export const Main = ({ children }: { children: ReactNode }): JSX.Element => (
  <FlexMain>{children}</FlexMain>
)

export const BigMain = ({ children }: { children: ReactNode }): JSX.Element => (
  <FlexBigMain>{children}</FlexBigMain>
)
