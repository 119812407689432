import * as React from 'react'

import styled from '@emotion/styled'

// import {
//   FacebookIcon,
//   InstagramIcon,
//   TwitterIcon,
//   VimeoIcon,
// } from '../../iconography'
// import { IconLink } from './IconLink'

// const iconSize = '16px'

const SocialIconsLayout = styled.nav`
  flex-grow: 1;
  & svg {
    margin-right: 0.5rem;
    &:hover {
      opacity: 0.6;
    }
  }
`

export const SocialIcons = (): JSX.Element => (
  <SocialIconsLayout>
    {/* 
    <IconLink linkTo="https://www.instagram.com/_carina_martins_/">
      <InstagramIcon size={iconSize} fillColor="#000" />
    </IconLink>
    <IconLink linkTo="https://vimeo.com/carinamrts/">
      <VimeoIcon size={iconSize} fillColor="#000" />
    </IconLink>
    <IconLink linkTo="https://www.facebook.com/CarinaMrts/">
      <FacebookIcon size={iconSize} fillColor="#000" />
    </IconLink>
    <IconLink linkTo="https://twitter.com/_carinamartins_/">
      <TwitterIcon size={iconSize} fillColor="#000" />
    </IconLink>
    */}
  </SocialIconsLayout>
)
