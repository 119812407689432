import React from 'react'

import styled from '@emotion/styled'
import { Link } from 'gatsby'

const StyledLanguageSelector = styled.ul`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  margin-bottom: 1rem;
`

const StyledLangLink = styled(Link)`
  margin-right: 1rem;
  margin-left: 1.5rem;
  text-decoration: none;
  position: relative;
  color: #000;
  font-weight: 400;
  margin-bottom: 20px;
  line-height: 23px;
  :last-of-type {
    margin-right: 0;
  }
  :not(.current):hover {
    right: 0;
    text-decoration: none;
    color: #000;
  }

  :not(.current):hover:after {
    border-color: #000;
    right: 0;
  }
  :not(.current):after {
    color: #000;
    border-radius: 1rem;
    border-top: 1px solid #000;
    content: '';
    position: absolute;
    right: 100%;
    bottom: -0.125rem;
    left: 0%;
    transition: right 0.4s cubic-bezier(0, 0.5, 0, 0.5),
      border-color 0.4s ease-in;
  }
  :not(.current):hover:after {
    animation: anchor-underline 2s cubic-bezier(0, 0.5, 0, 1);
    border-color: #000;
    color: #000;
  }
  @keyframes anchor-underline {
    0%,
    10% {
      left: 0;
      right: 100%;
    }
    40%,
    60% {
      left: 0;
      right: 50%;
    }
    90%,
    100% {
      left: 0%;
      right: 50%;
    }
  }

  &.current {
    color: #000;
  }

  &.current::after {
    border-radius: 1rem;
    border-top: 1px solid #000;
    content: '';
    position: absolute;
    right: 60%;
    bottom: -0.125rem;
    left: 0%;
  }
`
const availableLanguages = ['en', 'pt']

export const LanguageToggles = ({
  lang = 'en',
  alternate,
}: {
  lang: 'en' | 'pt'
  alternate: string
}): JSX.Element => (
  <StyledLanguageSelector className="languages">
    {availableLanguages.map((lng: string) => (
      <li key={lng}>
        <StyledLangLink
          to={lng === lang ? '' : alternate}
          className={lng === lang ? 'current' : ''}
        >
          {lng.toUpperCase()}
        </StyledLangLink>
      </li>
    ))}
  </StyledLanguageSelector>
)
