import * as React from 'react'

import styled from '@emotion/styled'
import { Link } from 'gatsby'

import { LanguageToggles } from './LanguageToggles/LanguageToggles'
import { NavLinks, PTNavLinks } from './NavMenu/NavLinks'

type HeaderProps = {
  isProject?: boolean
}

const StyledHeader = styled.header<HeaderProps>`
  display: flex;
  position: sticky;
  flex-direction: row;
  padding-top: max(30px, 3%);
  top: 0;
  background-color: #fff;
  z-index: 997;
  padding-bottom: ${props =>
    props.isProject ? 'min(25px, 3%)' : 'max(50px, 3%)'};
`

export const StyledHeadingContainer = styled.div`
  flex-grow: 1;
  align-self: flex-end;
`

export const StyledHeading = styled.h1`
  /* letter-spacing: 0.05em; */
  font-weight: 400;
  font-size: 1.33rem;
  text-transform: uppercase;
  padding-bottom: 0.26rem;
`

export const RightNavigation = styled.div`
  display: flex;
  flex-direction: column;
`

export const Header = ({
  lang = 'en',
  menuBool,
  menuToggle,
  alternate,
  isProject = false,
}: {
  lang: 'en' | 'pt'
  menuBool: boolean
  menuToggle: React.Dispatch<React.SetStateAction<boolean>>
  alternate: string
  isProject?: boolean
}): JSX.Element => {
  const currentLang = lang

  return (
    <StyledHeader isProject={isProject}>
      <StyledHeadingContainer>
        <StyledHeading>
          <Link to={currentLang === 'pt' ? '/pt/' : '/'}>Carina Martins</Link>
        </StyledHeading>
      </StyledHeadingContainer>
      <RightNavigation>
        <LanguageToggles lang={currentLang} alternate={alternate} />
        {currentLang === 'pt' ? (
          <PTNavLinks menuBool={menuBool} menuToggle={menuToggle} />
        ) : (
          <NavLinks menuBool={menuBool} menuToggle={menuToggle} />
        )}
      </RightNavigation>
    </StyledHeader>
  )
}
