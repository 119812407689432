import React from 'react'

import styled from '@emotion/styled'

import { MenuClose } from '../../elements/iconography/MenuIcons'
import { MobileMenuStyledLink } from '../NavMenu/NavLinks'

const MenuContainer = styled.ul`
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  list-style-type: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  li {
    color: white;
    margin-bottom: 2rem;
    font-size: 20px;
  }
  margin-top: 4.6rem;
  width: 95.5%;
`

const MenuBackground = styled.div`
  background: rgba(178, 178, 178, 0.9);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100vh;
`

const MainMenu = styled.nav`
  z-index: 999;
  position: sticky;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100vh;
  display: flex;
`

export const MenuButton = styled.button`
  background-color: transparent;
  border: none;
  color: white;
  font-size: 2rem;
  /*   width: 32px;
  height: 32px; */
  &:hover {
    cursor: pointer;
  }
  width: 32px;
  height: 32px;
`

export const Menu = ({
  menuBool,
  lang,
  menuToggle,
}: {
  menuBool: boolean
  lang: 'en' | 'pt'
  menuToggle: React.Dispatch<React.SetStateAction<boolean>>
}): JSX.Element => {
  const selectedLang = lang
  return (
    <MainMenu>
      <MenuBackground />
      <MenuContainer>
        <li>
          <MenuButton onClick={() => menuToggle(!menuBool)}>
            <MenuClose size={'32'} />
          </MenuButton>
        </li>
        {selectedLang === 'pt' ? (
          <>
            <MobileMenuStyledLink
              mobile
              to="/pt/"
              className={undefined}
              color={'#FFF'}
              onClick={(e: Event) => {
                if (
                  (e?.target as HTMLAnchorElement)?.classList?.contains(
                    'active'
                  )
                ) {
                  menuToggle(!menuBool)
                }
              }}
            >
              PROJECTOS
            </MobileMenuStyledLink>
            <MobileMenuStyledLink
              mobile
              to="/pt/sobre"
              className={undefined}
              color={'#FFF'}
              onClick={(e: Event) => {
                if (
                  (e?.target as HTMLAnchorElement)?.classList?.contains(
                    'active'
                  )
                ) {
                  menuToggle(!menuBool)
                }
              }}
            >
              SOBRE
            </MobileMenuStyledLink>
            <MobileMenuStyledLink
              mobile
              to="/pt/contacto"
              className={undefined}
              color={'#FFF'}
              onClick={(e: Event) => {
                if (
                  (e?.target as HTMLAnchorElement)?.classList?.contains(
                    'active'
                  )
                ) {
                  menuToggle(!menuBool)
                }
              }}
            >
              CONTACTO
            </MobileMenuStyledLink>
          </>
        ) : (
          <>
            <MobileMenuStyledLink
              mobile
              to="/"
              className={undefined}
              color={'#FFF'}
              onClick={(e: Event) => {
                if (
                  (e?.target as HTMLAnchorElement)?.classList?.contains(
                    'active'
                  )
                ) {
                  menuToggle(!menuBool)
                }
              }}
            >
              WORK
            </MobileMenuStyledLink>
            <MobileMenuStyledLink
              mobile
              to="/about"
              className={undefined}
              color={'#FFF'}
              onClick={(e: Event) => {
                if (
                  (e?.target as HTMLAnchorElement)?.classList?.contains(
                    'active'
                  )
                ) {
                  menuToggle(!menuBool)
                }
              }}
            >
              ABOUT
            </MobileMenuStyledLink>
            <MobileMenuStyledLink
              mobile
              to="/contact"
              className={undefined}
              color={'#FFF'}
              onClick={(e: Event) => {
                if (
                  (e?.target as HTMLAnchorElement)?.classList?.contains(
                    'active'
                  )
                ) {
                  menuToggle(!menuBool)
                }
              }}
            >
              CONTACT
            </MobileMenuStyledLink>
          </>
        )}
      </MenuContainer>
    </MainMenu>
  )
}
