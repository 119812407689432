import React, { useEffect, useState } from 'react'
import type { ReactNode } from 'react'

import styled from '@emotion/styled'

import { Footer } from '../Footer/Footer'
import { Header } from '../Header/Header'
import { Menu } from '../Header/MobileMenu/MobileMenu'
import { Main } from '../Main/Main'
import Seo from '../Seo'

const BigLayout = styled.div`
  display: flex;
  margin: 0 auto;
  min-height: calc(100vh - 3px);
  flex-direction: column;
  max-width: 1250px;
  width: 84%;
  @media (max-width: 1250px) {
    width: 90%;
  }
  @media (min-width: 1441px) {
    max-width: 2500px;
  }

  font-size: 1rem;
  position: relative;
  top: 0;
`

export const Layout = ({
  title,
  location,
  children,
  lang = 'en',
  alternate,
  description,
  keywords,
  isProject,
}: {
  title: string
  location?: Location
  children: ReactNode
  lang?: 'en' | 'pt'
  alternate: string
  description?: string
  keywords?: string
  isProject?: boolean
}): JSX.Element => {
  const [menuState, setMenuState] = useState(false)
  useEffect(() => {
    if (menuState) {
      document.documentElement.style.setProperty(
        '--window-inner-height',
        `${window.innerHeight}px`
      )
      document.documentElement.classList.add('is-locked')
    } else {
      document.documentElement.classList.remove('is-locked')
    }
  }, [menuState])
  return (
    <>
      <BigLayout>
        <Seo
          title={title}
          location={location}
          lang={lang}
          description={description}
          keywords={keywords}
          alternate={alternate}
        />
        <Header
          lang={lang}
          menuBool={menuState}
          menuToggle={setMenuState}
          alternate={alternate}
          isProject={isProject}
        />
        <Main>{children}</Main>
        <Footer />
      </BigLayout>
      {menuState && (
        <Menu lang={lang} menuBool={menuState} menuToggle={setMenuState} />
      )}
    </>
  )
}
